import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { useTimeoutFn } from "react-use"
import PlayIcon from "assets/svg/play-icon.svg"
import PauseIcon from "assets/svg/pause-icon.svg"
import { NoStyleButton } from "./Button/NoStyleButton"

type $CircleProps = {
  $animationTime: number
  onCompleteTime: () => void
}

type $CircleFillProps = {
  $animationTime: number
  $isPlaying: boolean
}

const CircleFill = styled.circle<$CircleFillProps>`
  fill: transparent;
  color: ${({ theme }) => theme?.dark?.ui?.icon?.opacityHex};
  stroke-width: 2;
  stroke-dasharray: 500;
  stroke-dashoffset: 500;
  stroke-linecap: round;
  animation: stroke ${({ $animationTime }) => $animationTime * 4}ms steps(500)
    forwards ${({ $isPlaying }) => ($isPlaying ? "running" : "paused")};
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
`

/**
 * Adjustments to match the center of the circle with both svgs
 */
const PlayPauseDiv = styled.div<{ isPlaying: boolean }>`
  position: absolute;
  top: ${({ isPlaying }) => (isPlaying ? "19px" : "16px")};
  left: ${({ isPlaying }) => (isPlaying ? "19px" : "21px")};
`

const CommonIconCss = css`
  color: ${({ theme }) => theme?.localTheme?.ui?.icon?.opacityHex};
`

const StyledPauseIcon = styled(PauseIcon)`
  ${CommonIconCss};
`
const StyledPlayIcon = styled(PlayIcon)`
  ${CommonIconCss};
`

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const CircleSVG = styled.svg`
  transform: rotate(-90deg);
`

export const CircularProgress = (props: $CircleProps) => {
  const [isSlidePlaying, setIsSlidePlaying] = useState(true)
  const [showProgress, setShowProgress] = useState(true)

  const onTimerEnd = () => {
    setShowProgress(false)
    props.onCompleteTime()
  }

  const [_isReady, cancel, reset] = useTimeoutFn(
    onTimerEnd,
    props.$animationTime
  )

  const onTogglePlayPause = () => {
    isSlidePlaying ? cancel() : reset()
    setShowProgress(isSlidePlaying)
    setIsSlidePlaying(!isSlidePlaying)
  }

  useEffect(() => {
    setShowProgress(true)
    reset()
  }, [showProgress])

  return showProgress ? (
    <ProgressContainer onClick={onTogglePlayPause}>
      <CircleSVG height={50} width={50}>
        <CircleFill
          cx="50%"
          cy="50%"
          r="20"
          stroke="currentColor"
          $animationTime={props.$animationTime}
          $isPlaying={isSlidePlaying}
        />
      </CircleSVG>
      <PlayPauseDiv isPlaying={isSlidePlaying}>
        <NoStyleButton
          aria-label={
            isSlidePlaying ? "Pause Slideshow button" : "Play Slideshow Button"
          }
        >
          {isSlidePlaying ? (
            <StyledPauseIcon aria-label="Pause Hero Slideshow" />
          ) : (
            <StyledPlayIcon aria-label="Play Hero Slideshow" />
          )}
        </NoStyleButton>
      </PlayPauseDiv>
    </ProgressContainer>
  ) : null
}
