import styled, { css } from "styled-components"
import Slider from "react-slick"
import { mq, zIndexes } from "styles"
import { $DotUITheme, $IsFixed } from "typings/modules"

export const Container = styled.div`
  position: relative;
  color: ${({ theme }) => theme.localTheme?.canvas?.primary?.opacityHex};
  background: ${({ theme }) => theme.localTheme?.canvas?.primary?.opacityHex};
`

export const SlideProgressDiv = styled.div`
  position: absolute;
  z-index: ${zIndexes.zIndex8};
  right: 35px;
  bottom: 50px;

  ${mq.maxWidth("md")} {
    bottom: auto;
    top: calc(50vh - 82px);
    right: 24px;
  }

  height: 50px;
  width: 50px;
  cursor: pointer;
`

const dots = ({ $dotUiMode, $isFixed }: $DotUITheme & $IsFixed) => css`
  .slick-dots {
    bottom: ${$isFixed ? "auto" : "32px"};
    top: ${$isFixed ? "calc(50vh - 48px)" : "auto"};
    ${mq.minWidth("md")} {
      bottom: 48px;
      top: auto;
    }
    li {
      height: 16px;
      &:before {
        display: none;
      }
      button {
        cursor: pointer;

        &:focus-visible {
          outline: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex}
            auto 1px;
        }
        &:focus {
          &:not(:focus-visible) {
            outline: none;
          }
        }
      }
    }
  }
  .slick-dots li button:before {
    content: "";
    cursor: pointer;
    line-height: normal;
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 8px;
    background: ${({ theme }) => theme[$dotUiMode].text.primary.opacityHex};
    border-radius: 50%;
    opacity: 1;
    ${mq.minWidth("md")} {
      background: ${({ theme }) => theme.localTheme.text.primary.opacityHex};
    }
  }
  .slick-dots li.slick-active button:before {
    opacity: 1;
    background-color: transparent;
    box-shadow: 0 0 0 3px
      ${({ theme }) => theme[$dotUiMode].text.primary.opacityHex};
    bottom: 32px;
    ${mq.maxWidth("md")} {
      bottom: 18px;
    }
    ${mq.minWidth("md")} {
      box-shadow: 0 0 0 3px
        ${({ theme }) => theme.localTheme.text.primary.opacityHex};
    }
  }
`

export const StyledSlider = styled(Slider)<$IsFixed & $DotUITheme>`
  position: relative;
  min-height: ${({ $isFixed }) => ($isFixed ? "100vh" : "100%")};
  ${dots};
  .slick-track {
    display: flex;
    .slick-slide {
      height: auto;
      & > div {
        height: 100%;
        & > div {
          height: 100%;
        }
      }
    }
  }
  ${mq.minWidth("md")} {
    min-height: ${({ $isFixed }) => ($isFixed ? "80vh" : "100%")};
  }
  ${mq.minWidth("lg")} {
    min-height: auto;
    height: ${({ $isFixed }) => ($isFixed ? "80vh" : "100%")};
  }
`
