import React from "react"
import { SanityHeroList } from "typings/graphql"
import { HeroList } from "./HeroList"

export const SanityHeroListBlock = ({
  index,
  fields,
}: {
  fields: SanityHeroList
  index: number
}) => <HeroList index={index} {...fields} />

export default SanityHeroListBlock
