import React, { useContext, useRef } from "react"
import { ThemeContext, ThemeProvider } from "styled-components"
import loadable from "@loadable/component"
import { SanityHeroList } from "typings/graphql"
import { ModeType } from "typings/modules"
import { Container, SlideProgressDiv, StyledSlider } from "./HeroList.styles"
import { CircularProgress } from "components/UI/CircularProgress"

const HeroAutoLayout = loadable(() => import("./HeroAutoLayout"))
const HeroFixedLayout = loadable(() => import("./HeroFixedLayout"))
const HeroTextLayout = loadable(() => import("./HeroTextLayout"))

const componentMap = {
  text: HeroTextLayout,
  fixed: HeroFixedLayout,
  auto: HeroAutoLayout,
}

type Props = {
  index: number
} & SanityHeroList

export const HeroList = (sanityProps: Props) => {
  const DEFAULT_SLIDER_CHANGE_TIME = 5000
  const slideChangeTimeMs =
    parseInt(sanityProps?.slideChangeTime) * 1000 || DEFAULT_SLIDER_CHANGE_TIME
  const themeContext = useContext(ThemeContext)
  const items = sanityProps.items ?? []
  const isSlider = items.length > 1
  const display = sanityProps.display as keyof typeof componentMap
  const Component = display ? componentMap[display] : HeroTextLayout
  const isFixed = display === "fixed"
  const [firstSlide] = items
  const mode = (sanityProps.theme ?? "light") as ModeType
  const mobileMode = (sanityProps.mobileTheme ?? "light") as ModeType
  const dotUiMode = isFixed ? mode : mobileMode
  const index = display !== "text" ? { index: sanityProps.index } : {}
  const sliderRef = useRef(null)
  const slickNext = () => {
    sliderRef?.current?.slickNext()
  }

  return (
    <ThemeProvider
      theme={{
        localTheme: themeContext[mode],
        localMobileTheme: themeContext[mobileMode],
      }}
    >
      <Container>
        {isSlider ? (
          <>
            <StyledSlider
              key={`slider-${items
                .map(item => item?.id?.toString().slice(0, 6))
                .join("")}`}
              ref={sliderRef}
              $dotUiMode={dotUiMode}
              dots
              customPaging={i => {
                return <button aria-label={`Page ${i}`} />
              }}
              arrows={false}
              infinite
              initialSlide={0}
              lazyLoad="ondemand"
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}
              $isFixed={isFixed}
            >
              {items?.map((item, i) => {
                const slideIndex = i === 0 ? index : {}
                return (
                  <Component
                    key={`hero-slide-${item?.id}`}
                    $isSlideshow={true}
                    item={item}
                    $mode={mode}
                    $mobileMode={mobileMode}
                    {...slideIndex}
                  />
                )
              })}
            </StyledSlider>
            <SlideProgressDiv>
              <CircularProgress
                $animationTime={slideChangeTimeMs}
                onCompleteTime={slickNext}
              />
            </SlideProgressDiv>
          </>
        ) : (
          <Component
            $isSlideshow={false}
            item={firstSlide}
            $mode={mode}
            $mobileMode={mobileMode}
            {...index}
          />
        )}
      </Container>
    </ThemeProvider>
  )
}
